import React from 'react';
import { FormattedMessage } from 'react-intl-next';

import InfoIcon from '@atlaskit/icon/glyph/info';
import { Box, Inline, xcss } from '@atlaskit/primitives';
import Button from '@atlaskit/button';

import { SidePanelFooter } from '@confluence/side-panel';

import { messages } from './messages';
import { AtlassianIntelligenceIcon } from './assets/AtlassianIntelligenceIcon';

export const IssueCreateSidePanelFooter = () => {
	return (
		<SidePanelFooter paddingBlock="space.100" paddingInline="space.100">
			<Inline spread="space-between" alignBlock="center">
				<Box xcss={boxStyle}>
					<Box xcss={infoIconStyle}>
						<InfoIcon label="information" size="small" />
					</Box>
					<Button
						appearance="subtle-link"
						spacing="none"
						href="https://www.atlassian.com/trust/atlassian-intelligence"
						rel="noopener"
						target="_blank"
						testId="issue-create-side-panel.ai-footer.disclaimer-text"
					>
						<Box xcss={textColorStyle}>
							<FormattedMessage {...messages.sidePanelFooterDisclaimerText} />
						</Box>
					</Button>
				</Box>
				<Box xcss={boxStyle}>
					<Box xcss={textColorStyle}>
						<FormattedMessage {...messages.sidePanelFooterAIText} />
					</Box>
					<Box xcss={aiIconStyle}>
						<AtlassianIntelligenceIcon />
					</Box>
				</Box>
			</Inline>
		</SidePanelFooter>
	);
};
const boxStyle = xcss({
	font: 'font.body.small',
	display: 'flex',
	alignItems: 'center',
});

const infoIconStyle = xcss({
	paddingRight: 'space.050',
	lineHeight: '16px',
});

const aiIconStyle = xcss({
	paddingLeft: 'space.050',
	lineHeight: '6px',
});

const textColorStyle = xcss({
	color: 'color.text.subtlest',
});
