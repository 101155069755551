import React, { useCallback } from 'react';

import { Box, xcss } from '@atlaskit/primitives';

import { SidePanel } from '@confluence/side-panel';
import { useSidePanelStateActions } from '@confluence/side-panel-state-container';

import { IssueCreateSidePanelFooter } from './IssueCreateSidePanelFooter';
import { IssueCreateSidePanelBody } from './IssueCreateSidePanelBody';
import { IssueCreateSidePanelHeader } from './IssueCreateSidePanelHeader';
import type { IssueCreateSidePanelProps } from './__types__/IssueCreateSidePanel';

// Currently a magic number, but will be replaced with a proper calculation
export const headerHeight = 56;

export const sidePanelTestId = 'issue-create-side-panel';
export const ISSUE_CREATE_SIDE_PANEL_ID = 'issue-create-side-panel';
export const ISSUE_CREATE_WITH_AI_SIDE_PANEL_ID = 'issue-create-with-ai-side-panel';
export const ISSUE_CREATE_SIDE_PANEL_WIDTH = 320;

const sidePanelStyle = xcss({
	position: 'relative',
});

export const IssueCreateSidePanel = ({ onClose, isCreatedWithAi }: IssueCreateSidePanelProps) => {
	const { showSidePanel, hideSidePanel } = useSidePanelStateActions();

	const handleOnClose = useCallback(() => {
		hideSidePanel();
		onClose();
	}, [hideSidePanel, onClose]);

	showSidePanel();

	return (
		<Box testId={sidePanelTestId} xcss={sidePanelStyle}>
			<SidePanel isFixed={true} headerHeight={headerHeight}>
				<IssueCreateSidePanelHeader onClose={handleOnClose} />
				<IssueCreateSidePanelBody isCreatedWithAi={isCreatedWithAi} onClose={handleOnClose} />
				{isCreatedWithAi && <IssueCreateSidePanelFooter />}
			</SidePanel>
		</Box>
	);
};
