import { getAnalyticsWebClient } from '@confluence/analytics-web-client';

import { convertSelectionToLegacyFormat } from './convertSelectionToLegacyFormat';

/**
 * Note: This is a hack to get the old create Jira Issue working.
 * Once we rewrite create Jira Issue in React this should hook up directly to invoke whatever the invoke handler it has.
 */
export const createJiraIssueSummaryHandle = (container, contentId, lastModified?) => () => {
	const selection = window.getSelection();
	if (selection) {
		// Send an event to track the user clicking the JIRA Link
		void getAnalyticsWebClient().then((client) => {
			client.sendUIEvent({
				action: 'clicked',
				actionSubject: 'button',
				actionSubjectId: 'jiraIssueLink',
				source: 'HighlightContextMenu',
			});
		});

		window.dispatchEvent(
			new CustomEvent(
				'create-jira-issue-summary',
				// Convert window.getSelection() to legacy format used in jira issue summary.
				// Once we Reactified it this should be deleted.
				{
					detail: convertSelectionToLegacyFormat(
						container,
						selection.getRangeAt(0),
						contentId,
						lastModified,
					) as any,
				},
			),
		);
	}
};

/**
 * Note: This is a hack to get the old create Jira Issue working.
 * Once we rewrite the old create Jira Issue in React this should hook up directly to invoke whatever the invoke handler it has.
 */
export const createMultipleJiraIssuesHandle = (container, contentId, lastModified?) => () => {
	const selection = window.getSelection();
	if (selection) {
		window.dispatchEvent(
			new CustomEvent('create-jira-issue-summary', {
				detail: convertSelectionToLegacyFormat(
					container,
					selection.getRangeAt(0),
					contentId,
					lastModified,
				) as any,
			}),
		);

		const observer = new MutationObserver((_mutations, observer) => {
			const createMultipleButton = document.querySelector(
				'.aui-button.aui-button-link.js-text-suggestion.js-go-to-create-multiple-issues',
			) as HTMLElement;
			if (createMultipleButton) {
				createMultipleButton.click();
				observer.disconnect();
			}
		});

		observer.observe(document.body, { childList: true, subtree: true });
	}
};
