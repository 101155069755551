import { AI_MATE_URL } from '../../common/constants';
import type { FetchIssueCreateDataProps, SuggestedIssue } from '../../common/types';

export const fetchAiSuggestedFields = async ({
	cloudId,
	confluencePage,
	highlightedText,
}: FetchIssueCreateDataProps): Promise<SuggestedIssue | undefined> => {
	try {
		const response = await fetch(AI_MATE_URL, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json;charset=UTF-8',
				'X-Experience-Id': 'ai-issue-create',
				'X-Product': 'jira',
				'Atl-CloudId': cloudId,
			},
			body: JSON.stringify({
				recipient_agent_named_id: 'ai_issue_create_agent',
				agent_input_context: {
					application: 'Confluence',
					context: {
						confluence_page_url: confluencePage,
						highlighted_text: highlightedText,
					},
					suggested_issues_config: {
						max_issues: 1,
						suggested_issue_field_types: [
							{
								issue_type: 'Task',
								fields: [
									{
										field_name: 'Summary',
										field_type: 'Short-Text',
									},
									{
										field_name: 'Description',
										field_type: 'Paragraph',
									},
								],
							},
						],
					},
				},
			}),
			credentials: 'include',
		});

		const data = await response.json();
		const suggestion = parseData(data);
		if (!suggestion) {
			throw new Error('Parsing response from ai-issue-create failed');
		}
		return suggestion;
	} catch (error: Error | unknown) {
		throw new Error('Request to ai-issue-create agent failed');
	}
};

export const parseData = (data: any): SuggestedIssue | undefined => {
	const content = data?.message?.content;
	if (!content) {
		return undefined;
	}
	const contentJson = JSON.parse(content);

	return {
		summary: contentJson.suggested_issues[0].field_values.Summary,
		description: {
			version: 1,
			type: 'doc',
			content: [
				{
					type: 'paragraph',
					content: [
						{
							type: 'text',
							text: contentJson.suggested_issues[0].field_values.Description,
						},
					],
				},
			],
		},
	};
};
