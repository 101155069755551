import { doc, p } from '@atlaskit/adf-utils/builders';

import {
	ISSUE_CREATE_SIDE_PANEL_ID,
	ISSUE_CREATE_SIDE_PANEL_WIDTH,
	ISSUE_CREATE_WITH_AI_SIDE_PANEL_ID,
} from '@confluence/issue-create-side-panel';

export const SUMMARY_MAX_LENGTH = 254;

const splitText = (text: string, maxLength: number): [string, string] => {
	const hellip = '…';
	if (text.length <= maxLength) {
		return [text, ''];
	}
	// Calculate the length of the first part without including the ellipsis
	const firstPartLength = maxLength - hellip.length;
	// Split the text into two parts
	const firstPart = text.substring(0, firstPartLength) + hellip;
	const secondPart = text.substring(firstPartLength);
	return [firstPart, secondPart];
};

export const createSingleJiraIssueHandle =
	(rightSidebarContext, actions, activeSelection) => () => {
		const selectionString = activeSelection?.toString() || '';
		const [summary, description] = splitText(selectionString, SUMMARY_MAX_LENGTH);

		actions.setSummary(summary);
		actions.setDescriptionAdf(doc(p(description)));

		rightSidebarContext.open(ISSUE_CREATE_SIDE_PANEL_ID, ISSUE_CREATE_SIDE_PANEL_WIDTH);
	};

export const createSingleAiJiraIssueHandle =
	(rightSidebarContext, actions, activeSelection) => () => {
		const docNodeSelection = doc(p(activeSelection?.toString() || ''));

		actions.setHighlightedText(docNodeSelection);

		rightSidebarContext.open(ISSUE_CREATE_WITH_AI_SIDE_PANEL_ID, ISSUE_CREATE_SIDE_PANEL_WIDTH);
	};
