import { useCallback, useEffect, useState } from 'react';

import { type DocNode } from '@atlaskit/adf-schema';

import { type ConfluenceAiIssueCreateState, type SuggestedIssue } from '../../common/types';
import { fetchAiSuggestedFields } from '../../services/fetch-ai-suggested-fields/utils';

type UseFetchAiSuggestedFields = {
	cloudId: string;
	confluencePage: string;
	highlightedText: DocNode;
	onStateChange: (newState: ConfluenceAiIssueCreateState) => void;
};

type LoadingState = { state: 'loading'; fields: undefined };
type ErrorState = { state: 'error'; fields: undefined };
type SuccessState = { state: 'idle'; fields: SuggestedIssue };
type FetchState = LoadingState | ErrorState | SuccessState;

export const useFetchAiSuggestedFields = ({
	cloudId,
	confluencePage,
	highlightedText,
	onStateChange,
}: UseFetchAiSuggestedFields): FetchState & { trigger: () => Promise<void> } => {
	const [fetchState, setFetchState] = useState<FetchState>({ state: 'loading', fields: undefined });

	const fetchFields = useCallback(async () => {
		setFetchState({ state: 'loading', fields: undefined });
		try {
			const suggestion = await fetchAiSuggestedFields({
				cloudId,
				confluencePage,
				highlightedText,
			});
			if (suggestion) {
				setFetchState({ state: 'idle', fields: suggestion });
			} else {
				setFetchState({ state: 'error', fields: undefined });
			}
		} catch (e: Error | unknown) {
			setFetchState({ state: 'error', fields: undefined });
		}
	}, [cloudId, confluencePage, highlightedText]);

	useEffect(() => {
		fetchFields();
	}, [fetchFields]);

	useEffect(() => {
		if (onStateChange) {
			onStateChange(fetchState.state);
		}
	}, [fetchState.state, onStateChange]);

	return { ...fetchState, trigger: fetchFields };
};
