import React, { Fragment, useState, useCallback, useMemo, useContext } from 'react';
import idx from 'idx';
import { defineMessages, useIntl } from 'react-intl';
import { styled } from '@compiled/react';

import Button from '@atlaskit/button/custom-theme-button';
import ChevronDownIcon from '@atlaskit/icon/glyph/hipchat/chevron-down';
import MediaServicesNoImageIcon from '@atlaskit/icon/glyph/media-services/no-image';
import { N40 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import Popup from '@atlaskit/popup';
import Tooltip from '@atlaskit/tooltip';

import {
	VIEW_PAGE_CONTEXT_MENU_FORGE_EXPERIENCE,
	ExperienceTrackerContext,
} from '@confluence/experience-tracker';
import {
	extensionTitle,
	ForgeKeyboardShortcut,
	ForgeKeyboardShortcutVisualizer,
} from '@confluence/forge-ui';
import type { ForgeUIExtensionType } from '@confluence/forge-ui';
import {
	openContextMenuClick,
	simulateItemClick,
	simulateItemMouseOver,
} from '@confluence/legacy-keyboard-shortcut-helpers/entry-points/legacyKeyboardShortcutHelpers';

import { DropDownComponentList, IconImage, NoIconWrapper } from './highlightActionsStyled';
import { AppIcon } from './AppIcon';

const i18n = defineMessages({
	selectApp: {
		id: 'highlight.actions.select.app',
		defaultMessage: 'Select app',
		description: 'Tooltip label for the dropdown menu to show other apps',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const PopupPaddingContainer = styled.div({
	padding: `${token('space.200', '16px')} ${token('space.300', '24px')}`,
});

type OtherActionsProps = {
	connectItems: any[];
	forgeItems?: ReadonlyArray<ForgeUIExtensionType>;
	selectForgeExtension?: (extension: ForgeUIExtensionType) => void;
};

export const OtherActions = ({
	connectItems,
	forgeItems = [],
	selectForgeExtension = () => {},
}: OtherActionsProps) => {
	const intl = useIntl();
	const [isOpen, setIsOpen] = useState(false);
	const experienceTracker = useContext(ExperienceTrackerContext);

	const onClose = useCallback(() => {
		setIsOpen(false);
	}, [setIsOpen]);

	const connectComponents = useMemo(
		() =>
			connectItems.map((item) => {
				return (
					<Button
						iconBefore={
							idx(item, (_) => _.icon.path) ? (
								<IconImage src={item.icon.path} />
							) : (
								<NoIconWrapper>
									<MediaServicesNoImageIcon
										label="No icon"
										size="small"
										secondaryColor="inherit"
										primaryColor={token('color.icon.disabled', N40)}
									/>
								</NoIconWrapper>
							)
						}
						key={item.completeKey}
						appearance="subtle"
						data-key={item.completeKey}
						// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
						className={item.styleClass}
						href={item.url}
						style={{
							// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
							textAlign: 'left', // we can't wrap Button with `styled` as inner styles override this
							// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
							borderRadius: 0,
						}}
						shouldFitContainer
						title={item.label}
					>
						{item.label}
					</Button>
				);
			}),
		[connectItems],
	);

	const forgeComponents = useMemo(
		() =>
			forgeItems.map((item) => {
				const appTitle = extensionTitle(item);

				return (
					<ForgeKeyboardShortcutVisualizer key={item.id} module={item}>
						<Button
							key={item.id}
							id={item.id}
							appearance="subtle"
							style={{
								// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
								textAlign: 'left', // we can't wrap Button with `styled` as inner styles override this
								// eslint-disable-next-line @atlaskit/design-system/ensure-design-token-usage/preview
								paddingLeft: connectItems.length ? '40px' : '8px',
								// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
								borderRadius: 0,
							}}
							onClick={() => {
								experienceTracker.start({
									name: VIEW_PAGE_CONTEXT_MENU_FORGE_EXPERIENCE,
									id: item.id,
									timeout: 10000,
								});
								selectForgeExtension(item);
							}}
							shouldFitContainer
							title={appTitle}
						>
							{appTitle}
						</Button>
					</ForgeKeyboardShortcutVisualizer>
				);
			}),
		[connectItems, forgeItems, selectForgeExtension, experienceTracker],
	);

	const forgeKeyboardShortcuts = useMemo(
		() =>
			forgeItems.map((module) => (
				<ForgeKeyboardShortcut
					key={module.id}
					module={module}
					action={() => {
						openContextMenuClick();
						simulateItemClick(module.id);
						simulateItemMouseOver(module.id);
					}}
				/>
			)),
		[forgeItems],
	);

	const dropDownComponents = useMemo(
		() => (
			<DropDownComponentList>
				{connectComponents}
				{forgeComponents}
			</DropDownComponentList>
		),
		[connectComponents, forgeComponents],
	);

	if (!connectItems.length && !forgeItems.length) {
		return null;
	}

	return (
		<Popup
			placement="bottom-start"
			isOpen={isOpen}
			onClose={onClose}
			/* eslint-disable-next-line jsx-a11y/no-autofocus */
			autoFocus={false} // keep this param as FF unselect the text after the trigger button is pressed
			content={() => <PopupPaddingContainer>{dropDownComponents}</PopupPaddingContainer>}
			trigger={(triggerProps) => (
				<div {...triggerProps}>
					<Tooltip content={intl.formatMessage(i18n.selectApp)}>
						{(tooltipProps) => (
							<Button
								{...tooltipProps}
								id="context-menu-other-apps"
								aria-expanded={isOpen}
								appearance="subtle"
								isSelected={isOpen}
								onClick={() => setIsOpen(!isOpen)}
								iconBefore={
									<Fragment>
										<AppIcon size="medium" label="" isSelected={isOpen} />
										<ChevronDownIcon size="medium" label="Drop down" />
									</Fragment>
								}
								style={{
									// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
									borderTopLeftRadius: 0,
									// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
									borderBottomLeftRadius: 0,
									// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
									height: '36px',
									// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
									margin: 0,
								}}
								testId="context-menu-other-apps"
							/>
						)}
					</Tooltip>
					{forgeKeyboardShortcuts}
				</div>
			)}
		/>
	);
};
