// We have deprecated emotion. Please use compiled instead
// eslint-disable-next-line no-restricted-imports, @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import styled from '@emotion/styled';

import { token } from '@atlaskit/tokens';
import { N30, N20 } from '@atlaskit/theme/colors';

// WS-2881 - We need to adjust the padding for the buttons to match the editor and shrink the "Add issue" icon
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-styled-tagged-template-expression -- Ignored via go/DSP-18766
export const BuiltInActions = styled.div`
	padding: ${token('space.025', '2px')};
	display: flex;
	border-right: 1px solid ${token('color.border', N30)};

	& > button,
	& > button:hover {
		margin: ${token('space.025', '2px')} ${token('space.100', '8px')};
		height: inherit;
		line-height: normal;
		display: flex;
		align-items: center;

		& > span:last-of-type {
			margin-bottom: ${token('space.025', '2px')};
		}
	}

	& > button:last-of-type span[role='presentation'] {
		height: 20px;
		width: 20px;
	}
`;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const DisabledButtonWrapper = styled.div({
	position: 'relative',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const DisabledButtonOverlay = styled.div({
	position: 'absolute',
	width: '100%',
	height: '100%',
	zIndex: 1000,
	backgroundColor: 'transparent',
	cursor: 'not-allowed',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const CurrentSelectionSpan = styled.span<{
	top: number;
	left: number;
	width: number;
	height: number;
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
}>(({ top, left, width, height }) => ({
	opacity: 0,
	position: 'absolute',
	pointerEvents: 'none',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	top,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	left,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	width,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	height,
}));

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const PopupWrapper = styled.div({
	padding: token('space.250', '20px'),
	maxWidth: 500,
	maxHeight: 500,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const DropDownComponentList = styled.div({
	display: 'flex',
	flexDirection: 'column',
	paddingTop: token('space.100', '8px'),
	paddingBottom: token('space.100', '8px'),
	// The Inline dialog has build-in padding, next margin will void it
	margin: `${token('space.negative.200', '-16px')} ${token('space.negative.300', '-24px')}`,
	overflowY: 'auto',
	maxHeight: 300,
	maxWidth: 300,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const IconImage = styled.img({
	height: 24,
	width: 24,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const NoIconWrapper = styled.div({
	width: 24,
	height: 24,
	borderRadius: '50%',
	backgroundColor: `${token('color.background.disabled', N20)}`,
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'space-around',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const Divider = styled.div({
	margin: `5px ${token('space.025', '2px')}`,
	width: 1,
	backgroundColor: token('color.border', N30),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-styled-tagged-template-expression -- Ignored via go/DSP-18766
export const StyledPopupPanel = styled.div`
	@keyframes popup {
		0% {
			opacity: 0;
		}

		100% {
			opacity: 1;
		}
	}

	position: absolute;
	transition:
		top 100ms ease-out,
		left 100ms ease-out;
	animation: popup 100ms forwards linear;
`;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
export const ButtonContainer = styled.div<{ left: number }>(({ left = 0 }) => ({
	transform: `translate(calc(-50% - 5px), calc(-100% - 20px))`,
	boxShadow: token(
		'elevation.shadow.overlay',
		'0px 3px 5px rgba(9, 30, 66, 0.2), 0px 0px 1px rgba(9, 30, 66, 0.31)',
	),
	borderRadius: 3,
	position: 'absolute',
	top: 0,
	// eslint-disable-next-line @atlaskit/design-system/ensure-design-token-usage/preview, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	left,
	backgroundColor: token('elevation.surface.overlay', '#fff'),
	display: 'flex',
}));
