import React from 'react';

import { GicAnywhere } from '@atlassian/gic-anywhere';
import { ConfluenceAiIssueCreate } from '@atlassian/ai-issue-create';

import { useSessionData } from '@confluence/session-data';

import type { IssueCreateSidePanelBodyProps } from './__types__/IssueCreateSidePanelBody';
import { useIssueCreateSidePanelContext } from './IssueCreateSidePanelContextProvider';

const noop = () => {};

export const IssueCreateSidePanelBody = ({
	onClose,
	isCreatedWithAi,
}: IssueCreateSidePanelBodyProps) => {
	const { cloudId } = useSessionData();

	const [state] = useIssueCreateSidePanelContext();

	return (
		<>
			{isCreatedWithAi ? (
				<ConfluenceAiIssueCreate
					cloudId={cloudId}
					confluencePage="https://hello.atlassian.net/wiki/spaces/~701210b0399f03a204a2496095a926e0f0802/pages/2509555448/Memory"
					highlightedText={state.highlightedText}
					consumer="confluence-highlight-issue-create"
					onSuccess={noop}
					onFailure={noop}
					onClose={onClose}
					onStateChange={noop}
				/>
			) : (
				<GicAnywhere
					consumer="confluence-highlight-issue-create"
					onSuccess={noop}
					onClose={onClose}
					onFailure={noop}
					summary={state.summary}
					descriptionAdf={state.descriptionAdf}
				/>
			)}
		</>
	);
};

export const constuctSmartlinkTag = (url: string) => {
	return `<a href="${url}" data-card-appearance="inline">${url}</a>`;
};
