import type { Action } from 'react-sweet-state';
import { createHook, createStore } from 'react-sweet-state';

import type { DocNode } from '@atlaskit/adf-schema';
import { doc, p } from '@atlaskit/adf-utils/builders';

export type IssueCreateSidePanelContextType = {
	summary?: string;
	descriptionAdf?: DocNode;
	highlightedText?: DocNode;
};

type State = {
	summary: string;
	descriptionAdf: DocNode;
	highlightedText: DocNode;
};

const initialIssueCreateSidePanelState: State = {
	summary: '',
	descriptionAdf: doc(p('')),
	highlightedText: doc(p('')),
};

const issueCreateSidePanelActions = {
	setSummary:
		(summary: string): Action<State> =>
		({ setState }) => {
			setState({ summary });
		},
	setDescriptionAdf:
		(descriptionAdf: DocNode): Action<State> =>
		({ setState }) => {
			setState({ descriptionAdf });
		},
	setHighlightedText:
		(highlightedText: State['highlightedText']): Action<State> =>
		({ setState }) => {
			setState({ highlightedText });
		},
};

const Store = createStore({
	initialState: {
		...initialIssueCreateSidePanelState,
	},
	actions: issueCreateSidePanelActions,
	name: 'issueCreateSidePanelContext',
});

export const useIssueCreateSidePanelContext = createHook(Store);
