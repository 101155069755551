import React from 'react';

import { di } from 'react-magnetic-di';

import { doc, p } from '@atlaskit/adf-utils/builders';
import { Box, xcss } from '@atlaskit/primitives';
import { GicAnywhere } from '@atlassian/gic-anywhere';

import { type ConfluenceAiIssueCreateProps } from '../../common/types';
import { useFetchAiSuggestedFields } from '../../services/fetch-ai-suggested-fields';

const DEFAULT_TIMEOUT = 7 * 1000; // 7s timeout, typical GIC load is ~3s

const aiIssueCreateWrapperStyle = xcss({
	flexGrow: '1',
});

export const ConfluenceAiIssueCreate = ({
	cloudId,
	confluencePage,
	highlightedText = doc(p('')),
	timeoutMs = DEFAULT_TIMEOUT,
	onSuccess,
	onFailure,
	onClose,
	onTimeout,
	testId,
	onStateChange,
}: ConfluenceAiIssueCreateProps) => {
	di(GicAnywhere, useFetchAiSuggestedFields);
	const { state, fields } = useFetchAiSuggestedFields({
		cloudId,
		confluencePage,
		highlightedText,
		onStateChange,
	});

	if (state === 'loading') {
		return <div>Loading...</div>;
	}
	if (state === 'error') {
		return <div>An error occurred when loading AI Issue Create.</div>;
	}

	return (
		<Box xcss={aiIssueCreateWrapperStyle} testId={testId ?? 'default-confluence-ai-issue-create'}>
			<GicAnywhere
				summary={fields.summary}
				descriptionAdf={fields.description}
				timeoutMs={timeoutMs}
				onSuccess={onSuccess}
				onFailure={onFailure}
				onClose={onClose}
				onTimeout={onTimeout}
				consumer={'confluenceHighlight'}
			/>
		</Box>
	);
};
