import { defineMessages } from 'react-intl-next';

export const messages = defineMessages({
	sidePanelHeader: {
		id: 'issue-create-side-panel.header',
		defaultMessage: 'Create Jira issue',
		description: 'Title of the side panel that allows users to create a Jira issue',
	},
	sidePanelFooterSubmit: {
		id: 'issue-create-side-panel.footer.submit',
		defaultMessage: 'Submit',
		description: 'Submit button text for the side panel that allows users to create a Jira issue',
	},
	sidePanelFooterCancel: {
		id: 'issue-create-side-panel.footer.cancel',
		defaultMessage: 'Cancel',
		description: 'Cancel button text for the side panel that allows users to close side panel',
	},
	sidePanelFooterDisclaimerText: {
		id: 'issue-create-side-panel.common.footer.disclaimer-text',
		defaultMessage: 'Content quality may vary',
		description: 'Info Text in the footer.',
	},
	sidePanelFooterAIText: {
		id: 'issue-create-side-panel.common.footer.ai-text',
		defaultMessage: 'Powered by AI',
		description: 'Text that describes that this feature is powered by Atlassian Intelligence',
	},
});
