import React from 'react';
import { FormattedMessage } from 'react-intl-next';

import { Flex, Box, xcss } from '@atlaskit/primitives';
import Heading from '@atlaskit/heading';
import CrossIcon from '@atlaskit/icon/glyph/cross';
import { IconButton } from '@atlaskit/button/new';
import { JiraIcon } from '@atlaskit/logo';

import { SidePanelHeader } from '@confluence/side-panel';

import { messages } from './messages';
import type { IssueCreateSidePanelHeaderProps } from './__types__/IssueCreateSidePanelHeader';

const headerMessageStyle = xcss({
	paddingInline: 'space.100',
});

export const IssueCreateSidePanelHeader = ({ onClose }: IssueCreateSidePanelHeaderProps) => {
	return (
		<SidePanelHeader>
			<Flex direction="row" justifyContent="space-between" alignItems="center">
				<Heading size="medium" as="h2">
					<Flex direction="row" justifyContent="space-between" alignItems="center">
						<Box>
							<JiraIcon size="small" appearance="brand" />
						</Box>
						<Box xcss={headerMessageStyle}>
							<FormattedMessage {...messages.sidePanelHeader} />
						</Box>
					</Flex>
				</Heading>
				<IconButton
					icon={CrossIcon}
					appearance="subtle"
					label="close"
					onClick={onClose}
					testId="close-issue-create-panel-button"
				/>
			</Flex>
		</SidePanelHeader>
	);
};
