import React from 'react';

import { Box, xcss } from '@atlaskit/primitives';

type SpaceTokens =
	| 'space.0'
	| 'space.025'
	| 'space.050'
	| 'space.075'
	| 'space.100'
	| 'space.150'
	| 'space.200'
	| 'space.250'
	| 'space.300'
	| 'space.400'
	| 'space.500'
	| 'space.600'
	| 'space.800'
	| 'space.1000';

type SidePanelFooterProps = {
	children: React.ReactNode;
	paddingBlock?: SpaceTokens;
	paddingInline?: SpaceTokens;
};

const footerBaseStyles = xcss({
	borderTopColor: 'color.border',
	borderTopStyle: 'solid',
	borderTopWidth: 'border.width',
});

export const SidePanelFooter = ({
	children,
	paddingBlock = 'space.200', //TODO: remove prop https://hello.jira.atlassian.cloud/browse/CBT-3990
	paddingInline = 'space.300',
}: SidePanelFooterProps) => {
	return (
		<Box paddingBlock={paddingBlock} paddingInline={paddingInline} xcss={footerBaseStyles}>
			{children}
		</Box>
	);
};
